import request from '@/utils/request'

// 查询个人信息
export function listClass(query) {
  return request({
    url: '/lytzs/order/pc/getCertInfo',
    method: 'post',
    params: query
  })
}

/**
 * 文件上传接口
 */
export function uploadFileAll(data) {
  return request({
    url: '/api/attached/upload',
    method: 'post',
    data
  })
}

/**
 * base64 盖章 文件上传接口
 */
export function uploadFileBase64New(data) {
  return request({
    url: '/api/attached/upload/signature/file',
    method: 'post',
    data
  })
}



/**
 *获取手机验证码 
 */
 export function getPhoneCode(data) {
  return request({
    url: '/api/sms/code',
    method: 'post',
    params: data
  })
}
// 提交投诉
export function sumbitComplaint(data) {
  return request({
    url: '/api/publicize/complaint',
    method: 'post',
    data
  })
}
export function viewComplaint(data) {
  return request({
    url: '/api/publicize/complaint/view',
    method: 'post',
    data
  })
}

export function booleanComplaint(data) {
  return request({
    url: '/api/publicize/complaint/boolean',
    method: 'post',
    data
  })
}
export function maliceComplaint(data) {
  return request({
    url: '/api/publicize/complaint/malice',
    method: 'post',
    data
  })
}

// 查询列表
export function getList(data) {
  return request({
    url: '/api/publicize/list',
    method: 'post',
    data
  })
}

// 查询列表 履约合同
export function getListLyht(data) {
  return request({
    url: '/api/publicize/lyht/list',
    method: 'post',
    data
  })
}


// 查询详情
export function getDetail(data) {
  return request({
    url: '/api/publicize/info',
    method: 'post',
    data
  })
}

// 查询详情 履约合同
export function getDetailLyht(data) {
  return request({
    url: '/api/publicize/lyht/info',
    method: 'post',
    data
  })
}

// 投诉跳转路径
export function goRedirect(data) {
  return request({
    url: '/api/publicize/redirect',
    method: 'post',
    data
  })
}

// 投诉查看跳转路径
export function goViewRedirect(data) {
  return request({
    url: '/api/publicize/details/redirect',
    method: 'post',
    data
  })
}





