import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/formulaHome',
    children: [
      {
        path: '/formulaHome',
        component: () => import('@/views/formulaHome'),
        name: 'FormulaHome',
        hidden: true,
        meta: { title: '公示列表' }
      },
      {
        path: '/detail',
        component: () => import('@/views/detail'),
        name: 'Detail',
        hidden: true,
        meta: { title: '公示详情' }
      },
      {
        path: '/lyhtgs',
        component: () => import('@/views/lyhtgs'),
        name: 'Lyhtgs',
        hidden: true,
        meta: { title: '履约合同公示详情' }
      },
      {
        path: '/cs',
        component: () => import('@/views/csForm'),
        name: 'CS',
        hidden: true
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    name: 'Login',
    hidden: true,
    meta: { title: '登录' }
  },
  {
    path: '*',
    component: () => import('@/views/formulaHome'),
    redirect: '/formulaHome',
  }
]

const router = new VueRouter({
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
